async function getPost(id, directoryPath) {
  const postType = directoryPath.split("/").slice(-1)[0];
  if (postType === "likes") {
    return {
      id,
      type: postType,
      english: await import(`~/${directoryPath}/${id}.md`)
    }
  } else {
    return {
      id,
      type: directoryPath.split("/").slice(-1)[0],
      german: await import(`~/${directoryPath}/${id}/de.md`),
      english: await import(`~/${directoryPath}/${id}/en.md`)
    }
  }
}

async function getPosts(directory, directoryPath) {
  const ids = new Set();
  directory.keys().forEach(post => ids.add(post.match(/\/([^.]+|.*\.md)\//)[1]));
  const posts = [];
  for (const id of ids) {
    const post = await getPost(id, directoryPath);
    posts.push(post);
  }
  return posts;
}

export async function getProjects() {
  return getPosts(require.context("../../content/projects", true), "content/projects");
}

export async function getProject(url) {
  return getPost(url, "content/projects");
}

export async function getNotes() {
  return getPosts(require.context("../../content/posts/notes", true), "content/posts/notes");
}

export async function getNote(id) {
  return getPost(id,"content/posts/notes");
}

export async function getReplies() {
  return getPosts(require.context("../../content/posts/replies", true), "content/posts/replies")
}

export async function getReply(id) {
  return getPost(id, "content/posts/replies");
}

export async function getLike(id) {
  return getPost(id, "content/posts/likes");
}
